<template>
  <div>
    <div class="row">
      <!-- Prescription -->
      <div class="col-md-12 pr-3">
        <div class="card">
          <div class="card-body pt-4">
            <div class="main-prescription" style="min-height: 760px">
              <div class="row">
                <div class="col-md-8 mt-6">
                  <h2 class="doctor">
                    <h5>{{ data.doctor_name }}</h5>
                  </h2>
                  <h4>{{ doctors.polyclinic_name }}</h4>
                </div>
                <div class="col-md-4 text-right">
                  <!-- <img src="/images/hayandra.jpeg" height="150" /> -->
                </div>
              </div>
              <div class="panel-body mt-3">
                <table class="table">
                  <tr>
                    <td>Tanggal: {{ data.display_date }}</td>
                    <td>Resep ID: {{ data.id }}</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Pasien: {{ data.patient_name }}</td>
                    <td>Pasien ID: {{ data.patient_id }}</td>
                    <td>Umur: {{ patient_age }}</td>
                    <td>Kelamin: {{ patients.gender }}</td>
                  </tr>
                </table>
              </div>
              <hr />
              <div class="col-md-12 clearfix" style="margin: 50px 0px">
                <div class="row">
                  <div class="col-md-5 left_panel">
                    <div class="panel-body">
                      <div class="pull-left">
                        <h5>
                          <strong>Riwayat: </strong> <br />
                          <br />
                          <p v-text="data.history"></p>
                        </h5>
                      </div>
                    </div>

                    <hr />

                    <div class="panel-body">
                      <div class="pull-left">
                        <h5>
                          <strong>Catatan:</strong> <br />
                          <br />
                          <p v-text="data.notes"></p>
                        </h5>
                      </div>
                    </div>

                    <hr />

                    <div class="panel-body">
                      <div class="pull-left">
                        <h5>
                          <strong>Saran: </strong> <br />
                          <br />
                          <p v-text="data.advice"></p>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="panel-body">
                      <div style="padding-left: 10px">
                        <strong style="border-bottom: 1px solid #000">
                          Rx
                        </strong>
                      </div>
                      <div>
                        <table class="table">
                          <tr>
                            <th>Nama</th>
                            <th>Dosis</th>
                            <th>Frekuensi</th>
                            <th>Hari</th>
                            <th>Instruksi</th>
                          </tr>
                          <tr v-for="mdc in displayMedicine" :key="mdc.id">
                            <td>{{ mdc.name }}</td>
                            <td>{{ mdc.dosage }}</td>
                            <td>{{ mdc.frequency }}</td>
                            <td>{{ mdc.days }}</td>
                            <td>{{ mdc.instruction }}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-4 pull-left"
                style="font-size: 12px; margin-top: 70px"
              >
                <hr />
                Tanda tangan
              </div>
              <div class="col-md-8 pull-right text-right">
                <h3 class="hospital">Medhop</h3>
                <h5>Tangerang Selatan, Banten</h5>
                <h5>0212345678</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import ApiService from "@/core/services/api.service.js";
export default {
  data() {
    return {
      // Data
      data: {},
      doctors: [],
      patients: [],
      patient_age: "",
      // Other
      imgLoaded: true,
      dataLoaded: false,
      splitMedicine: [],
      strMedicine: [],
      displayMedicine: [],
    };
  },

  components: {
    Card,
  },

  methods: {
    async printPrescription() {
      await window.print();
      this.$router.push("/prescriptions/list/" + this.$route.params.id);
    },

    async get() {
      this.data = await module.get("prescriptions/" + this.$route.params.id);
      console.log("data", this.data);

      this.displayMedicine = JSON.parse(this.data.medicine);
      console.log("displ", this.displayMedicine);

      this.splitMedicine = this.data.medicine;
      this.strMedicine = this.splitMedicine.replace(
        'border="1">',
        'border="0">'
      );
      console.log("ini", this.strMedicine);
      ApiService.get("doctors/" + this.data.doctor_id).then((response) => {
        console.log("doctors", response);
        this.doctors = response.data.data;
      });

      ApiService.get("patients/" + this.data.patient_id).then((response) => {
        console.log("patients", response);
        this.patients = response.data.data;

        this.getAge(response.data.data.birt_date);
      });

    if(this.data != null){
        this.dataLoaded = true;
    }

      if(this.dataLoaded == true){
          await window.print();
          this.$router.push("/prescriptions/detail/" + this.$route.params.id);
      }

      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push("/prescriptions/list");
      }
    },

    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      console.log("umur", age);
      this.patient_age = age;
      // return age;
    },
  },

  mounted() {
    // Get Data
    this.get();
    // this.printPrescription();
  },
};
</script>

<style scoped>
hr {
  margin-top: 0px;
  margin-bottom: 0px;
  border: 0;
  border-top: 1px solid #000;
}

.panel-body {
  background: #f1f2f7;
}

thead {
  background: transparent;
}

.bg_prescription {
  min-height: 760px;
  margin-top: 10px;
}

.prescription_footer {
  margin-bottom: 10px;
}

.bg_container {
  border: 1px solid #f1f1f1;
}

.panel {
  background: #fff;
}

.panel-body {
  background: #fff;
}

.margin_top {
  margin-top: 65px;
}

.wrapper {
  margin: 0px;
  padding: 60px 30px 0px 30px;
}

.doctor {
  color: #2f80bf;
}

.hospital {
  color: #2f80bf;
}

hr {
  border-top: 1px solid #f1f1f1;
}

.panel_button {
  margin: 10px;
}

.left_panel {
  border-right: 1px solid #ccc;
  margin-left: -15px;
}

th {
  border-bottom: 0px;
}

.col-md-4 {
  margin-right: 0px !important;
}

.patient {
  font-size: 12px;
}

.patient_name {
  font-size: 12px;
}

.prescription {
  font-size: 12px;
}

p {
  font-size: 12px;
}

.top_logo {
  margin-bottom: 10px;
}

@media print {
  .left_panel {
    border-right: 1px solid #ccc;
    margin-left: -15px;
  }

  .wrapper {
    margin: 0px;
    padding: 0px 10px 0px 0px;
  }

  .patient {
    width: 23%;
    float: left;
  }

  .patient_name {
    width: 31%;
    float: left;
  }

  .text-right {
    float: right;
  }

  .doctor {
    color: #2f80bf !important;
    font-size: 25px;
  }

  .hospital {
    color: #2f80bf !important;
  }

  .prescription {
    float: left;
  }

  .top_title {
    width: 70%;
  }

  .top_logo {
    width: 30%;
    margin-bottom: 10px;
  }

  .col-md-6 {
    width: 50%;
    float: left;
  }

  .col-md-5 {
    width: 45%;
    float: left;
  }

  .col-md-7 {
    width: 55%;
    float: left;
  }

  th {
    border-bottom: 0px;
  }
}
</style>